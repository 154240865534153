.questions {
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  height: 100vh;
  background-color: transparent;
}

.questions-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  font-weight: bold;
}

.questions-title {
  margin-bottom: 20px;
  font-size: 60px;
  margin-top: 0px;
  color: #fff;
  line-height: 1;
}

.question-block {
  margin-bottom: 20px;
}

.question-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  line-height: 1;
}

.question-input,
.question-select {
  width: 97.3%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 16px;
  line-height: 1.5; 
  background-color: white;
  height: 30px;
}


.question-select {
  box-sizing: border-box;
  height: 42px;
}

.next-button {
    transition: background-color 0.3s;
    box-shadow: 0 2px 4px rgba(202, 202, 202, 0.3);
    z-index: 2;
    color: #fff;
    border-radius: 70px;
    font-size: 18px;
    width: 411px;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    cursor: pointer;
    line-height: 1;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    font-weight: bold;
}

.next-button:hover {
  background-color: #283825;
}
.next-button:checked {
  background-color: #283825;
}

.passport-inputs {
}

.passport-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  line-height: 2.5;
  max-height: 42px;

}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  line-height: 1;
}

.error-input {
  border-color: red;
}

.passport-input:last-child {
  margin-right: 0;
}

@media screen and (max-width: 750px) {
  .questions-title {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
  }

  .questions-container {
    padding: 10px;
  }
  .next-button {
    font-size: 16px;
    max-width: 200px;
    height: 48px;
  }
}

@media screen and (max-width: 646px) {
.passport-input{

}
}

@media screen and (max-width: 620px) {
  .questions-container {
    max-width: 400px;
  }

  .question-input {
  }

  .passport-input {
  }

  .block {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .question-select {
  }
}

@media screen and (max-width: 430px) {
  .questions-container {
    max-width: 350px;
  }

  .passport-input {
    max-width: 130px;
  }

  .question-input {
    width: 95.3%;
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .questions-container {
    max-width: 350px;
  }

  .passport-input {
    max-width: 130px;
  }

  .question-input {
    width: 95.3%;
  }
}

@media screen and (max-width: 399px) {
  .questions-container {
    max-width: 310px;
  }

  .questions-title {
    font-size: 18px;
  }

  .question-label {
    font-size: 15px;
  }

  .question-select {
    font-size: 14px;
  }

  .passport-input {
    font-size: 14px;
  }
}

@media screen and (max-width: 364px) {
  .passport-input {
   
  }
}
@media screen and (max-width: 344px) {
  .passport-input {
    max-width: 100px;
  }
}
@media screen and (max-width: 350px) {
  .questions-container {
    max-width: 290px;
  }
}

@media screen and (max-width: 320px) {
  .questions-container {
    max-width: 270px;
  }
}
