@font-face {
  font-family: 'Gropled';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Gropled-Bold.otf) format("opentype"),
}

@font-face {
  font-family: 'Gropled';
  font-style: bold;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Gropled-Bold.otf) format("opentype")
}

