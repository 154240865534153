.survival-school-form {
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 2;
    position: relative;
}
.p{
    font-size: 32px;
    text-align: center;
    line-height: 1;
}
.Form{
    padding-left: 20px;
    padding-right: 20px;
    color:#fff;
    height: 100vh;
    background-color: transparent;
    font-family: "Gropled";
}
.survival-school-form div {
    margin-bottom: 20px;
}

.survival-school-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 1;
}

.survival-school-form textarea[type="text"],
.survival-school-form textarea,
.survival-school-form input,
.survival-school-form textarea[type="number"] {
    width: 96.8%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 5px;
    line-height: 1;
}

.survival-school-form button {
    transition: background-color 0.3s;
    box-shadow: 0 2px 4px rgba(202, 202, 202, 0.3);
    z-index: 2;
    color: #fff;
    border-radius: 70px;
    font-size: 18px;
    width: 150px;
    border: 1px solid #d9d9d9;
    background-color: transparent;
    cursor: pointer;
    line-height: 1;
    font-weight: bold;
    padding: 10px;

}

.survival-school-form button:hover {
    background-color: #1e321e;
}

@media screen and (max-width: 660px) {
    .survival-school-form {
        max-width: 500px;

    }
.survival-school-form button{
    font-size: 16px;
    height: 48px;
}
    .survival-school-form textarea[type="text"],
    .survival-school-form textarea,
    .survival-school-form input,
    .survival-school-form textarea[type="number"] {
        width: 95.7%;
    }
}

@media screen and (max-width: 560px) {
    .survival-school-form {
        max-width: 400px;

    }
.p{
    font-size: 24px;
}
    .survival-school-form textarea[type="text"],
    .survival-school-form textarea,
    .survival-school-form input,
    .survival-school-form textarea[type="number"] {
        width: 94.9%;
    }
}

@media screen and (max-width: 460px) {
    .survival-school-form {
        max-width: 300px;

    }

    .survival-school-form textarea[type="text"],
    .survival-school-form textarea,
    .survival-school-form input,
    .survival-school-form textarea[type="number"] {
        width: 93.9%;
    }
.p{
    font-size: 20px;
}
    label {
        font-size: 14px;
    }
}

@media screen and (max-width: 360px) {
    .survival-school-form {
        max-width: 270px;
        padding: 13px
    }

    .survival-school-form textarea[type="text"],
    .survival-school-form textarea,
    .survival-school-form input,
    .survival-school-form textarea[type="number"] {
        width: 92.5%;
    }

    label {
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {
    .survival-school-form {
        max-width: 250px;
        padding: 13px
    }

    .survival-school-form textarea[type="text"],
    .survival-school-form textarea,
    .survival-school-form input,
    .survival-school-form textarea[type="number"] {
        width: 91.5%;
    }

    label {
        font-size: 12px;
    }
}