.main-page {
  text-align: center;
}

.page {
  max-width: 100%;
  min-height: 100%;
  padding-bottom: 0px;
  margin: 0;
  background-color: black;
  color: #c9c9c9;
  font-family: "Gropled", "Arial", sans-serif;
  font-style: normal;
  box-sizing: border-box;
}
.body {
  margin: 0;
}
.closeButton {
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(202, 202, 202, 0.3);
  z-index: 2;
  color: black;
  border-radius: 70px;
  font-size: 15px;
  width: 150px;
  border: 1px solid black;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
}
.main__logo {
  max-width: 178px;
  max-height: 178px;
}
.footer__p {
  color: white;
  z-index: 10;
  margin-top: 80%;
  line-height: 1;

  text-align: center;
  position: relative;
}
.main__block {
  display: flex;
  padding-top: 100px;
  position: relative;
  z-index: 10;
  margin-right: 77px;
  margin-left: 77px;
}
.iskri {
  position: absolute;
  z-index: 2;
  left: 0;
  max-width: 864px;
  max-height: 619px;
  mask-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.9));
}
.main__text-block {
  max-width: 1015px;
  text-align: left;
  margin-left: 32px;
}
.main__header {
  font-size: 80px;
  margin-top: 16px;
  margin-bottom: 25px;
  line-height: 1;
}
.main__p {
  font-size: 31px;
  margin-top: 0;
  margin-bottom: 50px;
  line-height: 1;
}
.main__p2 {
  font-size: 20px;
  padding-left: 11px;
  border-left: 3px solid #9fc0af;
  max-width: 514px;
  line-height: 1;
}
.koster {
  position: absolute;
  z-index: 5;
  top: 1px;
  right: 0;
  max-width: 534px;
  max-height: 802px;
}

.zakat {
  position: absolute;
  z-index: 6;
  top: 500px;
  left: 0;
  max-width: 928px;
  max-height: 662px;
}

.about__block {
  margin-top: 600px;
  display: flex;
  position: relative;
  z-index: 20;
  justify-content: center;
  align-items: center;
  margin-right: 77px;
  margin-left: 77px;
}
.block__section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about__text {
  max-width: 585px;
  font-size: 19.53px;
  text-align: left;
  margin-bottom: 0;
  line-height: 1;
}
.about__title {
  font-size: 53.71px;
  align-self: start;
  margin-bottom: 19px;
  line-height: 1;
}
.about__title2 {
  align-self: end;
  font-size: 53.71px;
  margin-bottom: 19px;
  line-height: 1;
}
.about__image {
  max-width: 539px;
  max-height: 579px;
}

.gallery__block {
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
}
.gallery {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  margin-bottom: 40px;
  margin-left: 77px;
}

.gallery::-webkit-scrollbar {
max-height: 5px;
border-radius: 20px;
}

.gallery__image {
  flex: 0 0 auto;
  margin-right: 48px;
  max-width: 624px;
  max-height: 424px;
}
.gallery__title {
  font-size: 64px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  line-height: 1.25;
}
.gallery__text {
  font-size: 124px;
  color: #fff;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 77px;
  line-height: 1.25;
}

.galery::-webkit-scrollbar {
  display: none;
}
.logo2 {
  max-width: 1166px;
  max-height: 1166px;
  position: absolute;
  right: 0;
  top: 1600px;
  z-index: 3;
}

.koster2 {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 2;
  max-width: 100vw;
  margin-top: -290px;
  margin-left: auto;
  margin-right: auto;
}

.otziv__title {
  font-size: 60px;
  color: #fff;
  z-index: 2;
  position: relative;
  margin-left: 77px;
  margin-right: 77px;
  margin-top: 200px;
  text-align: start;
  max-width: 937px;
  line-height: 1;
}

.otziv__block {
  z-index: 2;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 70px;
  max-width: 1232px;
}
.otziv {
  max-width: 574px;
  background-color: #264233;
  padding: 30px;
  border-radius: 30px;
}
.Name {
  font-size: 36px;
  color: #fff;
  margin-top: 0;
  text-align: start;
  line-height: 1;
}
.text {
  font-size: 16px;
  color: #fff;
  font-weight: 200;
  text-align: start;
  line-height: 1;
}

.Next {
  z-index: 2;
  position: relative;
  font-size: 63px;
  text-align: start;
  color: #fff;
  margin-left: 77px;
  max-width: 650px;
  margin-bottom: 90px;
  margin-top: 200px;
  line-height: 1;
}
.button {
  position: absolute;
  left: 77px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(202, 202, 202, 0.3);
  z-index: 2;
  color: #fff;
  border-radius: 70px;
  padding: 37px;
  font-size: 35px;
  width: 411px;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  cursor: pointer;
  line-height: 1;
}
.button:hover {
  background-color: #264233;
}
.footer {
  font-size: 38px;
  color: #fff;
  z-index: 4;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 0;
  background-color: #010101;
}

@media screen and (max-width: 2000px) {
  .footer__p {
    margin-top: 60%;
  }
}
@media screen and (max-width: 1369px) {
  .zakat {
    max-width: 728px;
  }
  .otziv__block {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1220px) {
  .main__block {
    margin-right: 60px;
    margin-left: 60px;
  }
  .about__block {
    margin-right: 60px;
    margin-left: 60px;
  }
  .gallery {
    margin-left: 60px;
  }
  .gallery__text {
    margin-left: 60px;
  }
  .otziv__title {
    margin-left: 60px;
    margin-right: 60px;
  }
  .Next {
    margin-left: 60px;
  }
  .button {
    left: 60px;
  }
}

@media screen and (max-width: 1188px) {
  .koster {
    max-width: 434px;
    top: 80px;
  }
  .zakat {
    max-width: 628px;
  }
  .main__header {
    font-size: 72px;
  }
  .main__p {
    font-size: 26px;
    margin-bottom: 40px;
  }
  .main__p2 {
    font-size: 18px;
  }
  .about__title {
    font-size: 50px;
  }
  .about__title2 {
    font-size: 50px;
  }
  .about__text {
    font-size: 17px;
  }
  .gallery__title {
    font-size: 57px;
  }
  .gallery__text {
    font-size: 98px;
  }
  .otziv__title {
    font-size: 52px;
  }
  .otziv__block {
    gap: 50px;
  }
  .Next {
    font-size: 52px;
  }
  .block__section {
    max-width: 500px;
  }
  .logo2 {
    top: 1450px;
    max-width: 1106px;
    max-height: 1106px;
  }
  .gallery__image {
    max-width: 524px;
    max-height: 324px;
  }
  .koster2 {
    margin-top: -390px;
  }
}

@media screen and (max-width: 1100px) {
  .koster2 {
    margin-top: -90px;
  }
  .main__header {
    font-size: 64px;
  }
  .main__p {
    font-size: 22px;
  }
  .main__p2 {
    font-size: 16px;
  }
  .about__title {
    font-size: 46px;
  }
  .about__title2 {
    font-size: 46px;
  }
  .about__text {
    font-size: 16px;
  }
  .about__image {
    max-width: 439px;
    max-height: 479px;
  }
  .about__block {
    margin-top: 500px;
  }
  .block__section {
    max-width: 450px;
  }
  .gallery__title {
    font-size: 46px;
  }
  .gallery__block {
    margin-top: 150px;
    gap: 30px;
  }
  .gallery__text {
    font-size: 92px;
  }
  .otziv__title {
    font-size: 46px;
    margin-top: 150px;
  }
  .Next {
    font-size: 46px;
    max-width: 500px;
  }
  .zakat {
    top: 410px;
  }
  .main__logo {
    max-width: 128px;
    max-height: 128px;
  }
  .logo2 {
    top: 1200px;
  }
  .text {
    font-size: 15px;
  }
  .otziv {
    padding: 20px;
    max-width: 374px;
  }
  .otziv__block {
    justify-content: center;
  }
  .iskri {
    max-width: 664px;
    max-height: 619px;
  }
}

@media screen and (max-width: 1028px) {
  .koster {
    max-width: 374px;
  }
  .zakat {
    max-width: 528px;
  }
  .block__section {
    max-width: 420px;
    padding-left: 10px;
  }
  .gallery__block {
    margin-top: 110px;
  }
  .logo2 {
    max-width: 906px;
    max-height: 906px;
  }
  .gallery__text {
    font-size: 90px;
  }
  .main__block {
    padding-top: 60px;
  }
}

@media screen and (max-width: 950px) {
  .main__header {
  }
  .main__p {
  }
  .main__p2 {
  }
  .block__section {
    max-width: 350px;
    padding: 0;
  }
  .about__image {
    max-width: 339px;
  }
  .about__title {
    font-size: 42px;
  }
  .about__title2 {
    font-size: 42px;
  }
  .gallery__text {
    font-size: 86px;
  }
  .otziv__title {
    font-size: 44px;
  }
  .otziv__block {
    gap: 40px;
  }
  .otziv {
    max-width: 294px;
  }
  .about__block {
    margin-top: 400px;
  }
}
@media screen and (max-width: 900px) {
  .koster {
    max-width: 354px;
  }
  .zakat {
    max-width: 508px;
  }
  .main__header {
    font-size: 52px;
  }
  .main__p {
    font-size: 18px;
  }
  .main__p2 {
    font-size: 15px;
    max-width: 400px;
  }
  .button {
    width: 311px;
  }
}

@media screen and (max-width: 846px) {
  .iskri {
    max-width: 600px;
  }
  .main__header {
    font-size: 46px;
  }
  .main__p {
    font-size: 17px;
  }
  .main__p2 {
    max-width: 300px;
  }
  .zakat {
    top: 370px;
  }
  .koster2 {
    margin-top: 0px;
  }
  .about__block {
    flex-direction: column;
  }
  .block__section:nth-child(2) {
    flex-direction: column-reverse;
  }
  .block__section {
    max-width: 100%;
  }
  .about__image {
    max-width: 538px;
  }
  .gallery__image {
    max-width: 424px;
    max-height: 224px;
    margin-right: 30px;
  }
  .gallery__text {
    font-size: 82px;
  }
  .otziv__title {
    margin-top: 100px;
    font-size: 40px;
  }
  .gallery__block {
    margin-top: 50px;
  }
  .logo2 {
    max-width: 706px;
    max-height: 706px;
    top: 1820px;
  }
  .Next {
    font-size: 40px;
    margin-top: 100px;
  }
  .Name {
    font-size: 24px;
  }
}
@media screen and (max-width: 806px) {
  .koster {
    max-width: 324px;
  }
  .zakat {
    max-width: 480px;
  }
  .main__header {
    font-size: 46px;
  }
  .main__p {
    font-size: 17px;
  }
  .main__p2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 790px) {
  .footer1 {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .Next {
    margin-left: auto;
    margin-right: auto;
    max-width: 370px;
    text-align: center;
  }
  .button {
    margin-left: auto;
    margin-right: auto;
    position: unset;
  }
  .footer__p {
    margin-top: 350px;
  }
  .about__block {
    margin-top: 300px;
  }
  .main__block {
    margin-right: 50px;
    margin-left: 50px;
    padding-top: 60px;
  }
  .koster {
    max-width: 274px;
    top: 100px;
  }
  .zakat {
    max-width: 380px;
    top: 330px;
  }
  .main__header {
    font-size: 43px;
  }
  .main__p {
    font-size: 16px;
  }
  .main__p2 {
    font-size: 13px;
  }
  .main__logo {
    max-width: 108px;
    max-height: 108px;
  }
  .gallery__text {
    font-size: 79px;
  }
  .otziv__title {
    font-size: 36px;
    text-align: center;
  }
  .Next {
    font-size: 36px;
    margin-bottom: 50px;
  }
  .otziv__block {
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
  }
  .otziv {
    max-width: 400px;
  }
  .logo2 {
    top: 1700px;
  }
  .koster2 {
    margin-top: 400px;
  }
  .button {
    width: 250px;
    padding: 20px;
    font-size: 30px;
  }
  .about__text:first-of-type {
    margin-top: 0;
  }
}
@media screen and (max-width: 685px) {
  .iskri {
    max-width: 500px;
  }
  .main__block {
    margin-right: 40px;
    margin-left: 40px;
    padding-top: 40px;
  }
  .koster {
    max-width: 274px;
    top: 90px;
  }
  .zakat {
    max-width: 350px;
    top: 310px;
  }
  .main__header {
    font-size: 41px;
    margin-bottom: 20px;
  }
  .main__p {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .main__p2 {
    font-size: 13px;
  }
  .main__logo {
    max-width: 98px;
    max-height: 98px;
  }
}

@media screen and (max-width: 698px) {
  .iskri {
    max-width: 500px;
  }
  .main__block {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .main__text-block {
    margin-left: 0;
  }
  .koster {
    max-width: 274px;
    top: 70px;
  }
  .zakat {
    max-width: 350px;
    top: 310px;
  }
  .main__header {
    font-size: 41px;
    margin-bottom: 20px;
  }
  .main__p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .main__p2 {
    font-size: 16px;
  }
  .main__logo {
    max-width: 98px;
    max-height: 98px;
  }
  .about__block {
    margin-right: 40px;
    margin-left: 40px;
    margin-top: 250px;
  }
  .gallery__text {
    margin-left: 40px;
  }
  .gallery {
    margin-left: 40px;
  }
  .otziv__title {
    margin-left: 40px;
  }

  .logo2 {
    top: 1620px;
  }

  .footer__p {
    color: white;
    z-index: 10;
    margin-top: 250px;
    text-align: center;
  }
}

@media screen and (max-width: 582px) {
  .about__title {
    font-size: 36px;
  }
  .about__title2 {
    font-size: 36px;
    margin-top: 0;
  }
  .footer__p {
    margin-top: 250px;
  }
  .gallery__title {
    font-size: 44px;
  }
  .gallery__text {
    font-size: 70px;
  }
}
@media screen and (max-width: 582px) {
  .iskri {
    max-width: 450px;
  }
  .koster {
    max-width: 254px;
    top: 70px;
  }
  .zakat {
    max-width: 340px;
    top: 310px;
  }
}
@media screen and (max-width: 595px) {
  .about__block {
    margin-top: 230px;
  }
  .gallery__block {
    margin-top: 0;
  }
  .gallery__title {
    margin-top: 0;
  }
  .otziv__title {
    margin-top: 80px;
  }
  .Next {
    margin-top: 80px;
  }
  .logo2 {
    top: 1640px;
  }
}
@media screen and (max-width: 538px) {
  .iskri {
    max-width: 450px;
  }
  .koster {
    max-width: 244px;
    top: 70px;
  }
  .zakat {
    max-width: 310px;
    top: 310px;
  }
  .main__p2 {
    font-size: 12px;
    max-width: 280px;
  }
  .gallery__title {
    font-size: 36px;
  }
  .gallery__text {
    font-size: 60px;
  }
  .logo2 {
    top: 1440px;
  }
  .about__image {
    max-width: 438px;
  }
  .gallery__image {
    max-width: 364px;
    max-height: 204px;
  }
  .otziv__title {
    margin-bottom: 30px;
  }
  .koster2 {
    margin-top: 600px;
  }
  .gallery {
    margin-bottom: 20px;
  }
  .Next {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 515px) {
  .iskri {
    max-width: 440px;
  }
  .koster {
    max-width: 224px;
    top: 50px;
  }
  .zakat {
    max-width: 280px;
    top: 290px;
  }
  .main__p2 {
    font-size: 14px;
    max-width: 280px;
  }
  .main__header {
    font-size: 36px;
  }
  .main__p {
    font-size: 14px;
  }
  .about__block {
    margin-top: 180px;
  }
}

@media screen and (max-width: 490px) {
  .zakat {
    top: 280px;
  }
  .about__title {
    margin-top: 0;
  }
  .logo2 {
    max-width: 506px;
    max-height: 506px;
    top: 1400px;
  }
}
@media screen and (max-width: 470px) {
  .main__block {
    /*flex-direction: row;*/
    justify-content: space-between;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 20px;
  }
  .main__logo {
    max-width: 78px;
    max-height: 78px;
  }
  .iskri {
    max-width: 400px;
  }
  .koster {
    max-width: 204px;
    top: 0px;
  }
  .zakat {
    max-width: 260px;
    top: 260px;
  }
  .text {
    font-size: 14px;
  }
  .main__p2 {
    font-size: 14px;
    max-width: 220px;
  }
  .main__header {
    font-size: 30px;
  }
  .main__text-block {
    margin-left: 20px;
  }
  .main__p {
    font-size: 14px;
  }
  .otziv {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
  .logo2 {
    top: 1180px;
  }
  .about__block {
    margin-top: 200px;
  }
  .about__title {
    font-size: 34px;
  }
  .about__title2 {
    font-size: 34px;
  }
  .gallery__title {
    font-size: 34px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .otziv__title {
    font-size: 34px;
  }
  .koster2 {
    margin-top: 700px;
  }
  .Next {
    font-size: 34px;
  }
  .about__text {
    max-width: 100%;
    font-size: 14px;
  }
  .otziv__block {
    max-width: 400px;
  }
}
@media screen and (max-width: 450px) {
  .koster {
    top: 30px;
  }
  .about__block {
    margin-top: 160px;
  }
  .about__image {
    max-width: 400px;
  }
  .otziv__title {
    font-size: 32px;
  }
  .otziv__block {
    max-width: 300px;
  }
  .Next {
    font-size: 30px;
    max-width: 320px;
  }
  .gallery__text {
    font-size: 58px;
  }
  .zakat {
    max-width: 230px;
    top: 230px;
  }
  .main__p2 {
    font-size: 11px;
  }
  .about__text {
    font-size: 11px;
  }
}
@media screen and (max-width: 400px) {
  .about__image {
    max-width: 300px;
  }
  .gallery__image {
    max-width: 200px;
  }
  .gallery__title {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  .iskri {
    max-width: 300px;
    mask-image: none;
  }
  .about__title {
    font-size: 32px;
  }
  .about__title2 {
    font-size: 32px;
  }
  .gallery__title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .gallery__text {
    font-size: 50px;
  }
  .otziv__title {
    font-size: 28px;
    text-align: center;
    margin-top: 60px;
  }
  .button {
    width: 200px;
    font-size: 20px;
  }
  .Next {
    font-size: 24px;
  }
  .koster2 {
    margin-top: 800px;
  }
  .koster {
    max-width: 154px;
  }
  .footer__p {
    margin-top: 300px;
    font-size: 14px;
  }
  .logo2 {
    top: 1100px;
  }
}

@media screen and (max-width: 360px) {
  .about__block {
    margin-top: 120px;
  }
  .otziv__title {
    margin-left: auto;
    margin-right: auto;
  }
  .otziv__block {
    max-width: 280px;
  }
}

@media screen and (max-width: 320px) {
  .gallery__title {
    font-size: 28px;
  }
  .gallery__text {
    font-size: 40px;
  }
  .otziv__title {
    font-size: 28px;
  }
  .koster2 {
    margin-top: 850px;
  }
}
